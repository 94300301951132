<template>
  <div class="Login blank-page" data-col="blank-page">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header row"></div>
        <div class="content-body">
          <div class="auth-wrapper auth-cover">
            <div class="auth-inner row m-0">
              <!-- Brand logo-->

              <div class="row">
                <div class="col-xl-8" style="background-color: #ffffff">
                  <img
                    style="position: absolute; top: 5px; left: 5px; width: 150px"
                    src="/app-assets/images/vakilgiri-logo.png"
                    alt="logo"
                  />
                  <div
                    class="container d-none d-xl-block d-md-block d-lg-block text-center"
                    style="padding: 0px 100px 0px 100px"
                  >
                    <!-- ddddd -->

                    <div
                      id="carouselExampleControls"
                      class="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            src="/app-assets/images/Team-pana.png"
                            class="d-block w-100"
                            alt="image1"
                          />
                          <div class="d-block text-center">
                            <h4>Lorem, ipsum dolor.</h4>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Expedita, voluptate odit! Sint suscipit enim iste facere vero
                            debitis provident ratione temporibus nihil natus, at quos
                            ducimus tempora aliquam aliquid deserunt.
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img
                            src="/app-assets/images/slider-1.png"
                            class="d-block w-100"
                            alt="image2"
                          />
                          <div class="d-block text-center">
                            <h4>Lorem, ipsum dolor.</h4>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Expedita, voluptate odit! Sint suscipit enim iste facere vero
                            debitis provident ratione temporibus nihil natus, at quos
                            ducimus tempora aliquam aliquid deserunt.
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img
                            src="/app-assets/images/slider-4.png"
                            class="d-block w-100"
                            alt="image3"
                          />
                          <div class="d-block text-center">
                            <h4>Lorem, ipsum dolor.</h4>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Expedita, voluptate odit! Sint suscipit enim iste facere vero
                            debitis provident ratione temporibus nihil natus, at quos
                            ducimus tempora aliquam aliquid deserunt.
                          </div>
                        </div>
                      </div>
                      <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>

                    <!-- ddddddd -->
                  </div>
                </div>
                <div class="col-xl-4 p-5 text-white" style="background-color: #00364f">
                  <p class="mt-3 d-block d-xl-none d-md-none d-lg-none"></p>
                  <h2 class="card-title fw-bold mb-1 text-white">Forgot Password? 🔒</h2>
                  <p class="card-text mb-2">
                    Enter your email and we'll send you instructions to reset your
                    password
                  </p>
                  <div v-if="msgError">
                    <div class="alert alert-danger p-1" role="alert">
                      <small>{{ msgError.message }}</small>
                    </div>
                  </div>

                  <form class="auth-login-form mt-2" @submit.prevent="forgetPassword">
                    <div class="mb-1">
                      <label class="form-label text-white" for="login-email">Email</label>
                      <input
                        class="form-control"
                        type="email"
                        v-model="userEmail"
                        placeholder="example@example.com"
                      />
                    </div>

                    <button
                      type="submit"
                      class="btn btn-sm w-100 text-white mt-1"
                      style="background-color: #f21300; box-shadow: 1px 1px 3px #f21000"
                    >
                      Confirm
                    </button>
                  </form>
                  <p class="text-center mt-2">
                    <span>Go Back</span>
                    <router-link to="/master-distributer/login"
                      ><span style="color: #f21300">&nbsp;Login</span></router-link
                    >
                    <!-- <a href="#"></a> -->
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div>


  <div v-if="loading == true">
                <Spinner />
              </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/master-distributer/comman/Spinner.vue";
export default {
  name: "Login",
  components: {
    Spinner,
  },
  data() {
    return {
      loading: false,
      msgError: "",
      status: "",
      userEmail: "",
      userLoginData: {},
    };
  },
  created() {
    if (localStorage.getItem("accessToken") != null) {
      this.$router.push({ name: "SuperAdminHome" });
    }
  },
  methods: {
    forgetPassword() {
      this.loading = true;
      this.$axios
        .post("masterdistributer/forgot-password", { email: this.userEmail, logo: "dfgdfgfd" })
        .then((res) => {
          console.log(res.data);
          // this.userLoginData = res.data.data.user;
          // localStorage.setItem("userData", JSON.stringify(this.userLoginData));
          // localStorage.setItem("accessToken", res.data.data.token);
          // localStorage.setItem("portal", this.userLoginData.portal);
          localStorage.setItem("accessToken", "reset");
        
          toast.success("Link Sand To Email Successfully", {
              autoClose: 1000,
            });
          this.msgError = "";
          // this.$router.go({ path: "/master-distributer/home" });
          // this.$router.push({ name: "Home" });
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error.data.message;
          this.msgError = error.response.data;
          console.log(error.response.data);
        });
    },
  },
};
</script>
